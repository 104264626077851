import request from "@/utils/request";

export function getShowAllDataById(id) {
  return request({
    url: "/getShowAllDataById",
    method: "get",
    params: {
      id
    }
  });
}

export function getShowBaseScoreById(id) {
  return request({
    url: "/getShowBaseScoreById",
    method: "get",
    params: {
      id
    }
  });
}

// 积分排名
export function scoreBoardData(id) {
  return request({
    url: "/LsMain/ScoreBoardData",
    method: "get",
    params: {
      id
    }
  });
}

// 历史对阵
export function getShowBaseHistoryById(id) {
  return request({
    url: "/getShowBaseHistoryById",
    method: "get",
    params: {
      id
    }
  });
}

// 近期战绩
export function recentData(id) {
  return request({
    url: "/LsMain/RecentData",
    method: "get",
    params: {
      id
    }
  });
}

// 亚欧数据
export function asiaEuropeData(id) {
  return request({
    url: "/LsAsia/AsiaEuropeData",
    method: "get",
    params: {
      id
    }
  });
}
