<template>
  <div class="fenxi">
    <div class="bg-placeholder"></div>
    <div class="body">
      <div class="aside">
        <div class="back-icon" @click="$router.push('/display/step1')"></div>
        <div class="menu-wrap">
          <el-menu
            background-color="#16A085"
            text-color="#fff"
            active-text-color="#16A085"
            :default-active="activeMenu"
            router
          >
            <el-menu-item
              index="/display-fenxi/jibenmian/1"
              :route="{
                path: '/display-fenxi/jibenmian/1',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-document"></span>
                <span class="text">联赛积分排名</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/jibenmian/2"
              :route="{
                path: '/display-fenxi/jibenmian/2',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-document"></span>
                <span class="text">历史对阵</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/jibenmian/3"
              :route="{
                path: '/display-fenxi/jibenmian/3',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-document"></span>
                <span class="text">近期战绩</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/yaou/1"
              :route="{
                path: '/display-fenxi/yaou/1',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-record"></span>
                <span class="text">亚</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/yaou/2"
              :route="{
                path: '/display-fenxi/yaou/2',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-record"></span>
                <span class="text">欧</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/yaou/3"
              :route="{
                path: '/display-fenxi/yaou/3',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-record"></span>
                <span class="text">大小</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/display-fenxi/yapan"
              :route="{
                path: '/display-fenxi/yapan',
                query: { id }
              }"
            >
              <div slot="title" class="title">
                <span class="iconfont icon-statistics-1"></span>
                <span class="text">亚盘星辰解析</span>
                <span class="el-icon-arrow-right"></span>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
      </div>

      <div class="main">
        <div class="header">
          <div class="left">
            <span>{{ info.zteam }}</span>
            <img :src="info.zteam_logo_adress" alt="" />
          </div>
          <div class="center">
            <div class="time">{{ info.date_yyyy_mm_dd }} {{ info.time }}</div>
            <div class="vs">VS</div>
          </div>
          <div class="right">
            <img :src="info.kteam_logo_adress" alt="" />
            <span>{{ info.kteam }}</span>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { getShowAllDataById } from "@/api/fenxi.js";
export default {
  data() {
    return {
      id: 0,
      info: {
        zteam: "",
        kteam: ""
      }
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getShowAllDataById();
  },
  destroyed() {
    window.document.title = "摘星辰";
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    }
  },
  methods: {
    getShowAllDataById() {
      getShowAllDataById(this.id).then(res => {
        this.info = res.data;
        this.info.zteam_logo_adress = this.info.zteam_logo_adress.replace(
          /small/,
          "big"
        );
        this.info.kteam_logo_adress = this.info.kteam_logo_adress.replace(
          /small/,
          "big"
        );

        window.document.title = `${this.info.zteam} VS ${this.info.kteam}`;
      });
    },
    handleClick() {}
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/vars.scss";
.banner {
  display: block;
  margin-bottom: 10px;
}
.fenxi {

}
.bg-placeholder {
  background-image: url("../../assets/display/step3/bg.png");
  background-attachment: fixed;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.body {
  display: flex;
  padding: 15px;
  width: 1197px;
  margin: 0 auto;
  box-sizing: border-box;
  transform: scale(1.25);
  transform-origin: top;
  min-height: 600px;
  box-sizing: border-box;


  .aside {
    margin-right: 20px;
    padding-top: 42px;
    height: 400px;
    .back-icon {
    width: 137px;
    height: 44px;
    background: url("../../assets/display/step3/back.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 40px;
  }
    .menu-wrap {
      background: $--color-primary;
      margin-bottom: 15px;

      padding: 5px;
      .el-menu {
        width: 200px;
        border-right: none;
        .el-menu-item {
          .title {
            display: flex;
            .iconfont {
              margin-right: 10px;
            }
            .text {
              flex: 1;
            }
            .el-icon-arrow-right {
              line-height: inherit;
            }
          }
          &:not(:last-child) {
            &::after {
              display: block;
              content: "";
              width: 155px;
              height: 1px;
              background: #ebe8e8;
              opacity: 0.24;
              z-index: 100;
              position: relative;
              top: -2px;
              left: 5px;
            }
          }

          &.is-active {
            background-color: rgba(255, 255, 255, 0.8) !important;
          }
        }
      }
    }
    .weixin {
      width: 100%;
      position: absolute;
      bottom: 100px;
      height: 78px;
      background: #f6f7f8;
      display: flex;
      align-items: center;
      .icon-weixin {
        font-size: 32px;
        color: #c8c8c8;
        margin-left: 15px;
        margin-right: 15px;
        .content {
          font-size: 16px;
          line-height: 22px;
          color: #584f4f;
        }
      }
    }
  }

  .main {
    flex: 1;
    width: 0;
    .header {
      height: 117px;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .left,
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        img {
          width: 60px;
          margin: 0 25px;
        }
        span {
          font-size: 26px;
          font-weight: bold;
          line-height: 37px;
          color: #fff;
        }
      }
      .left {
        justify-content: flex-end;
      }
      .center {
        width: 120px;
        text-align: center;
        .time {
          font-size: 12px;
          line-height: 17px;
          color: #16a085;
          text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
            1px 1px 0 #fff;
        }
        .vs {
          font-size: 30px;
          font-weight: 800;
          line-height: 42px;
          color: #16a085;
          text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
            1px 1px 0 #fff;
        }
      }
    }
  }
}
</style>
