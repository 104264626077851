var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fenxi"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"aside"},[_c('img',{staticClass:"banner",attrs:{"src":require("@/assets/fenxi_bg.png"),"alt":""}}),_c('div',{staticClass:"menu-wrap"},[_c('el-menu',{attrs:{"background-color":"#16A085","text-color":"#fff","active-text-color":"#16A085","default-active":_vm.activeMenu,"router":""}},[_c('el-menu-item',{attrs:{"index":"/fenxi/jibenmian","route":{
              path: '/fenxi/jibenmian',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-document"}),_c('span',{staticClass:"text"},[_vm._v("基本面")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/fenxi/yaou","route":{
              path: '/fenxi/yaou',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-record"}),_c('span',{staticClass:"text"},[_vm._v("亚欧数据")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/fenxi/yapan","route":{
              path: '/fenxi/yapan',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-statistics-1"}),_c('span',{staticClass:"text"},[_vm._v("亚盘星辰解析")]),_c('span',{staticClass:"el-icon-arrow-right"})])])],1)],1)]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v(_vm._s(_vm.info.zteam))]),_c('img',{attrs:{"src":_vm.info.zteam_logo_adress,"alt":""}})]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.info.date_yyyy_mm_dd)+" "+_vm._s(_vm.info.time))]),_c('div',{staticClass:"vs"},[_vm._v("VS")])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":_vm.info.kteam_logo_adress,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.info.kteam))])])]),_c('router-view')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }