var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fenxi"},[_c('div',{staticClass:"bg-placeholder"}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"aside"},[_c('div',{staticClass:"back-icon",on:{"click":function($event){return _vm.$router.push('/display/step1')}}}),_c('div',{staticClass:"menu-wrap"},[_c('el-menu',{attrs:{"background-color":"#16A085","text-color":"#fff","active-text-color":"#16A085","default-active":_vm.activeMenu,"router":""}},[_c('el-menu-item',{attrs:{"index":"/display-fenxi/jibenmian/1","route":{
              path: '/display-fenxi/jibenmian/1',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-document"}),_c('span',{staticClass:"text"},[_vm._v("联赛积分排名")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/jibenmian/2","route":{
              path: '/display-fenxi/jibenmian/2',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-document"}),_c('span',{staticClass:"text"},[_vm._v("历史对阵")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/jibenmian/3","route":{
              path: '/display-fenxi/jibenmian/3',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-document"}),_c('span',{staticClass:"text"},[_vm._v("近期战绩")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/yaou/1","route":{
              path: '/display-fenxi/yaou/1',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-record"}),_c('span',{staticClass:"text"},[_vm._v("亚")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/yaou/2","route":{
              path: '/display-fenxi/yaou/2',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-record"}),_c('span',{staticClass:"text"},[_vm._v("欧")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/yaou/3","route":{
              path: '/display-fenxi/yaou/3',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-record"}),_c('span',{staticClass:"text"},[_vm._v("大小")]),_c('span',{staticClass:"el-icon-arrow-right"})])]),_c('el-menu-item',{attrs:{"index":"/display-fenxi/yapan","route":{
              path: '/display-fenxi/yapan',
              query: { id: _vm.id }
            }}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"iconfont icon-statistics-1"}),_c('span',{staticClass:"text"},[_vm._v("亚盘星辰解析")]),_c('span',{staticClass:"el-icon-arrow-right"})])])],1)],1)]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v(_vm._s(_vm.info.zteam))]),_c('img',{attrs:{"src":_vm.info.zteam_logo_adress,"alt":""}})]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.info.date_yyyy_mm_dd)+" "+_vm._s(_vm.info.time))]),_c('div',{staticClass:"vs"},[_vm._v("VS")])]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":_vm.info.kteam_logo_adress,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.info.kteam))])])]),_c('router-view')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }